import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import autoAnimate from "@formkit/auto-animate";
import FlagIcon from "@mui/icons-material/Flag";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Header, SearchInput, Text, Button, HeadingText } from "@components";
import { Storage, updateRecentData, imageUrlBuilder } from "@utils";
import { updateCustomDate } from "@redux/header/slice";
import { upsertRecentSearches } from "@redux/user/slice";
import { clubSearchApiList } from "@redux/teetime/actions";
import { toast } from "react-toastify";
import countryCode from "./../../countryCode/index.json";

const Carousel = ({ images, isCompact, data }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const carouselRef = useRef();

  const totalImages = data?.length + (images?.length ? 1 : 0);

  useEffect(() => {
    let intervalId = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex, totalImages]);

  const nextSlide = () => {
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % totalImages;
      if (nextIndex === 0) {
        setTimeout(() => {
          setIsTransitioning(false);
        }, 1000); // Match with transition duration
      }
      return nextIndex;
    });
  };

  const setSlide = (index) => {
    setIsTransitioning(true);
    setCurrentIndex(index);
  };

  const handleTransitionEnd = () => {
    if (currentIndex === totalImages) {
      setIsTransitioning(false);
      setCurrentIndex(0);
    }
  };

  const CarouselItem = ({ image, index }) => {
    const [shadow, setShadow] = useState(false);
    return (
      <button
        key={index}
        className="w-full h-full flex-shrink-0 relative"
        disabled={window.location.pathname === `/course/${image.id}`}
        onClick={() => navigate(`/course/${image.attributes?.name.toString()}`)}
      >
        <img
          src={image?.attributes?.photos?.[0]}
          className="w-full h-full object-cover"
        />
        <div
          className="absolute bottom-20 left-4 md:left-10 xl:left-20 h-[70px] flex items-center "
          onMouseEnter={() => setShadow(true)}
          onMouseLeave={() => setShadow(false)}
        >
          <img
            src={image?.attributes?.logo}
            className={`w-[55px] h-[55px] md2:w-[70px] md2:h-[70px] rounded-full bg-[#ffffff] ${
              shadow && "shadow-[0px_0px_20px_0px_#cdcaec]"
            }`}
          />
          <HeadingText
            className={"text-[18px] md2:text-[22px] text-[#ffffff] ml-2"}
          >
            {image?.attributes?.name}
          </HeadingText>
        </div>
      </button>
    );
  };

  return (
    <div
      className={`relative w-full ${
        isCompact ? "h-[545px] max-h-[545px]" : "h-[667px] max-h-[667px]"
      } overflow-hidden z-[0]`}
    >
      <div
        ref={carouselRef}
        className="absolute inset-0 flex"
        style={{
          transition: isTransitioning ? "transform 1s" : "none",
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        {images?.length ? (
          <div key={"index"} className="w-full h-full flex-shrink-0">
            <img
              src={images?.[0].attributes.url}
              alt={`header${10}`}
              className="w-full h-full object-cover"
            />
          </div>
        ) : null}
        {data?.length ? (
          data?.map((image, index) => (
            <CarouselItem image={image} index={index} key={index} />
          ))
        ) : (
          <></>
        )}
      </div>

      <div className="absolute bottom-4 right-4 flex space-x-4 z-10">
        {(images?.length ? ["", ...data] : data)?.map((_, index) => (
          <button
            key={index}
            onClick={() => setSlide(index)}
            className={`w-7 h-7 rounded-full border-[1px] flex justify-center items-center ${
              index === currentIndex ? "border-[#ffffff]" : "border-transparent"
            }`}
          >
            <div className="w-1 h-1 rounded-full bg-[#ffffff]" />
          </button>
        ))}
      </div>
    </div>
  );
};

const HeaderSection = ({
  headerImage,
  isCompact = true,
  searchInputProps,
  searchMenuProps,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customDate, ismobileMenuOpen } = useSelector(
    (store) => store.headerReducer
  );
  const { recentSearches } = useSelector((store) => store.userReducer);
  const { logo } = useSelector((state) => state.headerReducer);
  const { exploreGame, clubSearchList } = useSelector(
    (store) => store.teetimeReducer
  );
  const headerBgImages = logo?.data?.attributes?.bgImage?.data;
  const headerClubsData = logo?.data?.attributes?.clubs?.data;
  const announcementTxt = logo?.data?.attributes?.announcementText;
  const timeoutRef = useRef();

  var homeBgImg = "";
  var courseBgImg = "";
  setTimeout(() => {
    homeBgImg =
      logo?.data && logo?.data?.attributes?.bgImage?.data[0]?.attributes?.url;

    courseBgImg =
      logo?.data && logo?.data?.attributes?.bgImage?.data[1]?.attributes?.url;
  }, [2000]);

  const [state, setState] = useState({
    search: "",
    show: false,
    courseList: true,
    locationList: true,
    announcementText: "",
  });
  const { t } = useTranslation("common");

  const changeDate = (e) => dispatch(updateCustomDate({ date: e }));

  const showSuggestionBox = (e) => {
    e.stopPropagation();
    !state.show && setState((prev) => ({ ...prev, show: true }));
  };
  const hideSuggestionBox = () =>
    state.show && setState((prev) => ({ ...prev, show: false }));

  const setSearchData = (e) => {
    let payload = `${e.target.value}`;
    setState((prev) => ({ ...prev, search: payload }));
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      dispatch(clubSearchApiList(payload));
    }, 1000);
  };

  const updateRecentSearches = async (e) => {
    let recentSearchesData = [...recentSearches];
    updateRecentData(recentSearchesData, e);
    dispatch(upsertRecentSearches(recentSearchesData));
    Storage.save(Storage.RECENT_SEARCHES, JSON.stringify(recentSearchesData));
  };

  const onSearch = (e) => {
    let query = `?date=${moment(customDate).toISOString()}`;
    if (e === "text") {
      if (state.search.length) {
        if (clubSearchList?.location?.length) {
          if (
            clubSearchList?.location?.every((item) =>
              item?.country?.toLowerCase()?.includes(state.search.toLowerCase())
            )
          ) {
            query = query + "&country=" + clubSearchList?.location[0]?.country;
          } else {
            // if (clubSearchList?.location[0]?.place?.toLowerCase().includes(state.search.toLowerCase()) || clubSearchList?.location[0]?.country?.toLowerCase().includes(state.search.toLowerCase())) {
            query =
              query +
              "&place=" +
              clubSearchList?.location[0]?.place +
              "&country=" +
              clubSearchList?.location[0]?.country;
          }
        } else if (clubSearchList?.club?.length) {
          query = query + "&name=" + clubSearchList?.club[0]?.club;
        }
      }
      window.location.search !== query &&
        navigate({
          pathname: `/courses`,
          search: query,
        });
    } else {
      if (!e) {
        toast.error(t("header.retry_Search"));
        return;
      }
      if (e?.club) {
        query = query + "&name=" + e.club;
      }
      if (e?.place) {
        query = query + "&place=" + e.place;
      }
      if (e?.country) {
        query = query + "&country=" + e.country;
      }
      if (e?.slug) {
        query = query + "&slug=" + e.slug;
      } else if (e) {
        updateRecentSearches(e);
      }

      if (e?.club) {
        navigate(`/course/${e?.club.toString()}`);
      } else {
        navigate({
          pathname: `/courses`,
          search: query,
        });
      }
    }
  };

  const courseRef = useRef();
  useEffect(() => {
    if (courseRef.current) {
      autoAnimate(courseRef.current);
    }
  }, [courseRef]);
  const showMoreCourse = (e) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      courseList: !state.courseList,
    }));
  };

  const locationRef = useRef();
  useEffect(() => {
    if (locationRef.current) {
      autoAnimate(locationRef.current);
    }
  }, [locationRef]);
  const showMoreLocation = (e) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      courseList: false,
      locationList: !state.locationList,
    }));
  };

  const SearchMenu = ({
    suggestionSearch,
    recentSearch,
    clubSearchList,
    search,
    onSearch,
  }) => {
    const { club, location } = clubSearchList;

    return (
      <div className={SearchContainer}>
        {(club.length || location.length) && search.length ? (
          <>
            {club.length ? (
              <div className=" w-full h-auto" ref={courseRef}>
                <div
                  onClick={showMoreCourse}
                  className={`w-full flex justify-between items-center cursor-pointer bg-[white] pl-2 ${
                    state.courseList ? "rounded-t-lg" : "rounded-lg"
                  }`}
                >
                  <p className="text-[20px] text-[black] font-sans font-semibold">
                    {t("header.courses")}
                  </p>
                  <div
                    className={`w-fit h-fit ${
                      state.courseList
                        ? "duration-500 transform rotate-90"
                        : "duration-300 transform rotate-0"
                    }`}
                  >
                    <ArrowRightIcon
                      fontSize="large"
                      sx={{ color: "#000000" }}
                    />
                  </div>
                </div>
                {state.courseList && (
                  <div
                    className={`flex flex-col bg-[#ffffffad] h-auto max-h-[220px] overflow-x-hidden overflow-y-scroll custom-scroll ${
                      state.courseList ? " rounded-b-lg" : "rounded-lg"
                    }`}
                  >
                    {club?.map((ele, ind) => {
                      return (
                        <Button
                          key={ind}
                          onClick={() => onSearch(ele)}
                          variant="primary"
                          className="mt-1 flex items-center"
                        >
                          <span className="py-2 flex w-[80%] pl-2">
                            <FlagIcon className="text-primary self-center mr-2" />
                            <Text className={SearchSuggestionText}>
                              {ele?.club}
                            </Text>
                          </span>
                          <span className="py-1 my-1  px-3 text-white flex bg-primary rounded-xl min-w-[100px] hover:opacity-80">
                            {t("destination.book_Now")}
                          </span>
                        </Button>
                      );
                    })}
                  </div>
                )}
                <hr className="border-primary my-2" />
              </div>
            ) : null}
            {location.length ? (
              <div className=" w-full h-auto" ref={locationRef}>
                <div
                  onClick={showMoreLocation}
                  className={`w-full flex justify-between items-center cursor-pointer bg-[white] pl-2 ${
                    state.locationList ? "rounded-t-lg" : "rounded-lg"
                  }`}
                >
                  <p className="text-[18px] font-sans text-black font-semibold">
                    {t("header.locations")}
                  </p>
                  <div
                    className={`w-fit h-fit ${
                      state.locationList
                        ? "duration-500 transform rotate-90"
                        : "duration-300 transform rotate-0"
                    }`}
                  >
                    <ArrowRightIcon
                      fontSize="large"
                      sx={{ color: "#000000" }}
                    />
                  </div>
                </div>
                {state.locationList && (
                  <div
                    className={`flex flex-col bg-[#ffffffad] h-auto max-h-[200px] overflow-x-hidden overflow-y-scroll custom-scroll ${
                      state.locationList ? " rounded-b-lg" : "rounded-lg"
                    }`}
                  >
                    {location?.every(
                      (it) => it.country === location[0].country
                    ) && (
                      <Button
                        key={-1}
                        onClick={() =>
                          onSearch({ country: location[0].country })
                        }
                        variant="primary"
                        className="mt-1 pl-1"
                      >
                        <div className="py-2 flex gap-x-2 ">
                          <PlaceIcon className="text-primary  self-center" />
                          <img
                            style={{
                              width: "1.3em",
                              height: "0.8em",
                              marginTop: "0.3em",
                              marginRight: "0.2em",
                            }}
                            src={`https://flagcdn.com/w40/${
                              countryCode[location[0]?.country?.toLowerCase()]
                            }.png`}
                            srcSet={`https://flagcdn.com/w80/${
                              countryCode[location[0]?.country?.toLowerCase()]
                            }.png 2x`}
                            alt="flag"
                          />
                          <Text className={SearchSuggestionText}>
                            {`${location[0]?.country}, `}
                          </Text>
                          <span
                            className="font-sans font-thin italic text-black hover:none ml-1"
                            style={{ fontWeight: "lighter" }}
                          >{`${location?.length} golf club`}</span>
                        </div>
                      </Button>
                    )}
                    {location?.map((ele, ind) => {
                      return (
                        <Button
                          key={ind}
                          onClick={() => onSearch(ele)}
                          variant="primary"
                          className="mt-1 pl-1"
                        >
                          <div className="py-2 flex gap-x-2">
                            <PlaceIcon className="text-primary  self-center" />
                            <img
                              style={{
                                width: "1.3em",
                                height: "0.8em",
                                marginTop: "0.3em",
                                marginRight: "0.2em",
                              }}
                              src={`https://flagcdn.com/w40/${
                                countryCode[ele?.country?.toLowerCase()]
                              }.png`}
                              srcSet={`https://flagcdn.com/w80/${
                                countryCode[ele?.country?.toLowerCase()]
                              }.png 2x`}
                              alt="flag"
                            />
                            <Text className={SearchSuggestionText}>
                              {`${ele?.place}, ${ele?.country}`}
                            </Text>
                          </div>
                        </Button>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}
          </>
        ) : (
          <>
            {search?.length ? (
              <>
                <p className="text-[18px] font-sans text-black font-semibold">
                  {t("header.no_Results")}
                </p>
                <hr className="border-[#dddddddd] pb-5" />
              </>
            ) : recentSearch?.length > 0 ? (
              <>
                <p className="text-[18px] text-[black] font-semibold">
                  {t("header.recent_Search")}
                </p>
                <div className="flex flex-col">
                  {recentSearch?.map((ele, ind) => {
                    return (
                      <Button
                        key={ind}
                        onClick={() => onSearch(ele)}
                        variant="primary"
                      >
                        <p className="py-2 flex">
                          {ele?.type === "club" ? (
                            <FlagIcon className="text-primary mr-1 self-center" />
                          ) : (
                            <PlaceIcon className="text-primary mr-1 self-center" />
                          )}
                          <Text className="text-[18px] text-[black] font-semibold hover:underline ">
                            {ele?.type === "club"
                              ? ele?.club
                              : ele?.place
                              ? `${ele?.place}, ${ele?.country}`
                              : ele?.country}
                          </Text>
                        </p>
                      </Button>
                    );
                  })}
                </div>
                <hr className="border-[#dddddddd] pb-5" />
              </>
            ) : null}
            {suggestionSearch?.length ? (
              <>
                <p className="text-[18px] font-sans text-black font-semibold">
                  {t("header.quick_Search")}
                </p>
                <div className="flex flex-row flex-wrap gap-x-2">
                  {suggestionSearch?.map((val, ind) => {
                    const url = imageUrlBuilder(
                      val?.attributes?.image?.data &&
                        val?.attributes?.image?.data?.attributes.url
                    );
                    return (
                      <div className="flex flex-row p-2" key={ind}>
                        <Button
                          onClick={() => {
                            onSearch({ slug: val?.attributes?.slug });
                          }}
                          variant="primary"
                        >
                          <Li className="hover:bg-[#f0efef]">
                            <img src={url} height={30} width={30} alt="" />
                            <Text className="font-sans text-[14px] text-[black] pl-2">
                              {val?.attributes?.title}
                            </Text>
                          </Li>
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  };

  return (
    <Container onClick={hideSuggestionBox} className="relative">
      <Header
        searchInputProps={searchInputProps}
        searchMenuProps={searchMenuProps}
      />
      <div
        className={`${isCompact ? RootHeader2 : RootHeader}  ${
          !ismobileMenuOpen &&
          (announcementTxt?.length
            ? "xl:mt-[159px] xl2:mt-[159px] mt-[85px] md:mt-[40px]"
            : "xl:mt-[170px] xl2:mt-[170px] mt-[50px] md:mt-[60px]")
        } relative flex justify-center items-center`}
      >
        {headerBgImages?.length ? (
          <Carousel
            data={headerClubsData}
            images={headerBgImages}
            isCompact={isCompact}
          />
        ) : null}
        <div
          className={`absolute ${isCompact ? "mt-[-180px]" : "mt-[-150px]"}`}
        >
          <SearchInput
            isCompact={isCompact}
            value={state.search}
            selectedDate={customDate}
            setShow={showSuggestionBox}
            changeSelectedDate={changeDate}
            setSearch={setSearchData}
            goToSearch={onSearch}
            {...searchInputProps}
          />
          <div
            className={
              state.show
                ? "absolute z-[998] left-0 right-0 visible"
                : "absolute invisible h-0"
            }
          >
            <SearchMenu
              suggestionSearch={exploreGame?.data}
              recentSearch={recentSearches}
              clubSearchList={clubSearchList}
              search={state.search}
              onSearch={onSearch}
              {...searchMenuProps}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = tw.div`
bg-white
`;

const RootHeader = `
bg-no-repeat
bg-cover
bg-center
w-full
h-[667px]
max-h-[667px]
bg-[linear-gradient(to_bottom,rgba(0,0,0,0.3)_0%,rgba(0,0,0,0)_33%),var(--image-url)]
`;

const SearchSuggestionText = `
text-black
font-sans
font-semibold
hover:underline
underline-offset-2
`;

const RootHeader2 = `
bg-no-repeat
bg-cover
bg-center
w-full
h-[545px]
max-h-[545px]
bg-[linear-gradient(to_bottom,rgba(0,0,0,0.3)_0%,rgba(0,0,0,0)_33%),var(--image-url)]
`;

const Li = tw.div`
flex-1
flex 
p-2
justify-center
items-center
bg-white
flex
border-[#dddddddd] 
rounded-md 
self-center
`;

const SearchContainer = `
h-[300px] 
flex 
flex-col 
w-[90%] 
md:w-[63%] 
lg:w-[56%] 
xl:w-[65%] 
border-[gray] 
bg-[#ffffffcc] 
rounded-lg 
shadow-[0_0px_20px_#3c7fed90]
mt-5 
px-5
pt-2 
pb-5
text-white 
m-auto 
overflow-y-scroll 
overflow-x-hidden 
custom-scroll 
transparent`;

export default HeaderSection;
