import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DensityMediumRoundedIcon from "@mui/icons-material/DensityMediumRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Storage } from "@utils";
import { Button, Text, DropDown, CartsView, ChangeLanguage } from "@components";
import defaultUser from "@assets/images/defaultUser.png";
import c2hLogo from "@assets/images/c2hLogo.png";
import { Dialog } from "@headlessui/react";
import SearchBarHeader from "../SearchBarHeader";
import useScrollingHeader from "../../hooks/useScrollingHeader";
import { change_Language } from "../../redux/settings/slice";
import { arrangeNavData } from "../../utils/arrangeNavData";
import { setIsMobileMenuOpen } from "../../redux/header/slice";
import "../../App.css";

const AuthenticationRoute = ({ onPressLogin, onPressSignup }) => {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-row items-center justify-between gap-x-3">
      <Button
        onClick={onPressLogin}
        variant="primary"
        className={`${
          window.location.pathname !== "/signup" ? AuthActive : AuthInactive
        }`}
      >
        {window.location.pathname !== "/signup" ? (
          <Text className={`${AuthText} px-2`}>{t("auth.login")}</Text>
        ) : (
          <Text className={`${InactiveText} px-2`}>{t("auth.login")}</Text>
        )}
      </Button>
      <Button
        onClick={onPressSignup}
        variant="primary"
        className={`${
          window.location.pathname === "/signup" ? AuthActive : AuthInactive
        }`}
      >
        {window.location.pathname === "/signup" ? (
          <Text className={`${AuthText} px-2`}>{t("auth.signUp")}</Text>
        ) : (
          <Text className={`${InactiveText} px-2`}>{t("auth.signUp")}</Text>
        )}
      </Button>
    </div>
  );
};

const Accordion = ({ value, active, path, inActive, index, data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col w-[100%]">
      <div className="flex  w-full items-center cursor-pointer relative">
        <TopNavBar
          key={index}
          value={value}
          path={path}
          active={active}
          inActive={inActive}
        />
        {data?.length ? (
          <div className="absolute right-0">
            {open ? (
              <ExpandLessIcon
                sx={{ color: "white" }}
                onClick={() => setOpen(!open)}
              />
            ) : (
              <ExpandMoreIcon
                sx={{ color: "white" }}
                onClick={() => setOpen(!open)}
              />
            )}
          </div>
        ) : null}
      </div>
      {open && data?.length && (
        <div className={` w-full flex flex-col pr-5`} key={index}>
          {data?.map((item, ind) => {
            return (
              <div key={ind}>
                <p
                  className={`${
                    ind === 0 ? "" : "mt-3"
                  } block  pb-1 text-[16px] font-semibold text-gray-400 ml-2`}
                >
                  {item?.attributes?.title}
                </p>
                {item?.attributes?.child?.data?.map((value, i) => {
                  return (
                    <NavLink
                      to={value?.attributes?.url}
                      key={i}
                      className="my-1.5 block text-[14px] font-semibold text-gray-500 hover:text-gray-400 ml-4 "
                    >
                      {value?.attributes?.title}
                    </NavLink>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const OpenProfile = (props) => {
  const { t } = useTranslation("common");

  const options = [
    {
      index: 0,
      title: t("profile.profile"),
      value: "/profile",
    },
    {
      index: 1,
      title: t("profile.setting"),
      value: "/settings",
    },
    {
      index: 2,
      title: t("profile.logOut"),
      value: "/login",
    },
  ];

  const { userDetail } = useSelector((store) => store.authReducer);

  let fname = "User";
  let image = defaultUser;

  if (userDetail) {
    fname = userDetail?.firstname;
    image = userDetail?.image;
  }

  return (
    <div className="flex flex-row items-center pr-6 text-white ">
      <DropDown
        title={fname}
        options={options}
        titleStyle="max-w-[150px] truncate"
        image={image}
      />
    </div>
  );
};

const TopNavBar = ({ value, active, path, inActive }) => {
  const pathName = "/" + window.location.pathname.split("/")[1];
  return (
    <div className="w-full">
      <NavLink
        key={value}
        to={path}
        className={`${
          (pathName === "/course" ? "/courses" : pathName) === path
            ? active
            : inActive
        }  ${
          (pathName === "/course" ? "/courses" : pathName) !== path &&
          "header-underline-animation after:bg-[white] xl:after:bg-[black] h-[100%] "
        }`}
      >
        {value}
      </NavLink>
    </div>
  );
};

export default function Header({
  hideHeaderSearchBar,
  searchInputProps,
  searchMenuProps,
}) {
  const headerClassName = useScrollingHeader();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation("common");
  const { menus, logo, ismobileMenuOpen } = useSelector(
    (state) => state.headerReducer
  );
  const { userDetail, userToken } = useSelector((store) => store.authReducer);

  const logoPath =
    logo?.data && logo?.data?.attributes?.logo?.data?.attributes?.url;

  const headerNav = menus?.data?.filter(
    (val, ind) => val?.attributes?.slug === "header-nav"
  );
  const navlinks = headerNav && headerNav[0]?.attributes?.items?.data;

  let headerNavLinks = navlinks?.length ? arrangeNavData(navlinks) : [];

  const [imageExists, setImageExists] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [announcementTxt, setAnnouncementTxt] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  const [language, setLanguage] = useState({
    code: "GB",
    language: "en",
  });

  const [state, setState] = useState({
    show: false,
  });

  const checkImageExists = () => {
    const img = new Image();
    img.src = logoPath;

    img.onload = () => {
      setImageExists(true);
    };

    img.onerror = () => {
      setImageExists(false);
    };
  };

  React.useEffect(() => {
    checkImageExists();
  }, [logoPath]);

  useEffect(() => {
    const announcementTxt = logo?.data?.attributes?.announcementText;
    if (announcementTxt) {
      setAnnouncementTxt(announcementTxt);
    }
  }, [logo]);

  useEffect(() => {
    dispatch(setIsMobileMenuOpen(mobileMenuOpen));
  }, [mobileMenuOpen]);
  let yscroll = 0;

  useEffect(() => {
    if (window.screen.width >= 600) {
      window.addEventListener("scroll", function () {
        setSearchVisible(window.scrollY > (announcementTxt ? 400 : 350));
        if (
          (window.scrollY - yscroll < 0
            ? (window.scrollY - yscroll) * -1
            : window.scrollY - yscroll) > 300
        ) {
          setState((prev) => ({
            ...prev,
            show: false,
          }));
          yscroll = window.scrollY;
        }
      });
    }

    async function checkLanguage() {
      try {
        let selectLanguage = await Storage.get(Storage.LANGUAGE);
        if (selectLanguage === "de") {
          setLanguage({
            code: "DE",
            language: "de",
          });
        } else {
          setLanguage({
            code: "GB",
            language: "en",
          });
        }
      } catch (error) {}
    }

    checkLanguage();
  }, []);

  useEffect(() => {
    if (userDetail?.language === "en") {
      handleLanguageChange({
        code: "GB",
        language: "en",
      });
    } else if (userDetail?.language === "de") {
      handleLanguageChange({
        code: "DE",
        language: "de",
      });
    }
  }, [userDetail]);

  const onPressLogin = () => navigate("/login");

  const onPressSignup = (e) => {
    e?.stopPropagation();
    navigate("/signup");
  };

  const handleLanguageChange = (props) => {
    setLanguage(props);
    i18n.changeLanguage(props.language);
    Storage.save(Storage.LANGUAGE, props.language);
    dispatch(change_Language(props.language));
  };

  const showSuggestionBox = (e) => {
    e.stopPropagation();
    !state.show && setState((prev) => ({ ...prev, show: true }));
  };
  const hideSuggestionBox = () =>
    state.show && setState((prev) => ({ ...prev, show: false }));

  const setData = (ele, ind) => {
    const chunks = [];
    const childData = ele?.attributes?.child?.data;
    for (let i = 0; i < childData.length; i += 4) {
      chunks.push(childData.slice(i, i + 4));
    }
    return (
      <>
        {chunks.map((chunk, chunkIndex) => (
          <div
            className="border-r-[1px] border-[#bdedd3] pr-10 w-[25%]"
            key={chunkIndex}
          >
            <p className="mb-10 xl1:mb-4 block pb-1 text-[18px] font-semibold text-blue54 md:mx-2 h-[18px]">
              {chunkIndex === 0 ? ele?.attributes?.title : ""}
            </p>
            {chunk.map((val, index) => (
              <NavLink
                key={index}
                to={val?.attributes?.url}
                className="my-5 block text-[16px] font-semibold text-gray-500 hover:text-black md:mx-2 cursor-pointer hover:underline underline-offset-2"
              >
                {val?.attributes?.title}
              </NavLink>
            ))}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={`bg-black ${headerClassName}`} onClick={hideSuggestionBox}>
      <nav
        className={`container mx-auto px-3 flex justify-center ${LargeDeviceContainer}`}
        aria-label="Global"
      >
        <div
          className={`flex justify-center xl:w-24 w-[70px] py-2 xl:py-0 ${
            ismobileMenuOpen ? "hidden" : "flex"
          }`}
        >
          <NavLink to="/" className="webkitHighlight">
            <img
              className="img-fluid"
              src={imageExists ? logoPath : c2hLogo}
              alt=""
            />
          </NavLink>
        </div>
        {!hideHeaderSearchBar && searchVisible && !ismobileMenuOpen && (
          <div className="relative w-0 md2:w-[350px] xl:w-[250px] xl1:w-[400px] xl3:w-[500px] h-[30px] xl:h-[40px] rounded-full bg-[#ffffff] invisible md2:visible ">
            <SearchBarHeader
              showSuggestionBox={showSuggestionBox}
              hideSuggestionBox={hideSuggestionBox}
              show={state.show}
              searchInputProps={searchInputProps}
              searchMenuProps={searchMenuProps}
            />
          </div>
        )}
        <div
          className={`xl:hidden  flex md:flex-row flex-col-reverse items-end justify-center gap-x-2 ${
            ismobileMenuOpen ? "hidden" : "flex"
          }`}
        >
          <div className=" md:pb-0 pb-2">
            <CartsView />
          </div>
          <Button
            style={{ backgroundColor: "rgba(18,16,55,0.30)" }}
            variant="primary"
            className="rounded-md text-white h-10 w-10 webkitHighlight"
            onClick={() => {
              dispatch(setIsMobileMenuOpen(true));
              setMobileMenuOpen(true);
            }}
          >
            <DensityMediumRoundedIcon aria-hidden="true" />
          </Button>
        </div>

        <div className="xl:flex hidden justify-center items-center space-x-4">
          {userDetail && userToken ? (
            <OpenProfile navigate={navigate} dispatch={dispatch} />
          ) : (
            <AuthenticationRoute
              onPressSignup={onPressSignup}
              onPressLogin={onPressLogin}
            />
          )}
          <ChangeLanguage
            title={language}
            titleStyle="max-w-[150px] truncate"
            handleLanguageChange={handleLanguageChange}
          />
        </div>
      </nav>

      <nav
        className={`${navbarView} bg-white xl:flex hidden`}
        aria-label="Global"
      >
        <div className={`container sm:mx-auto  ${LgNavStyle}`}>
          <div className="xl:flex flex-row items-center justify-start w-full">
            {headerNavLinks &&
              [...headerNavLinks]
                ?.sort((a, b) => a?.attributes?.order - b?.attributes?.order)
                ?.map((item, index) => (
                  <div className="group h-[100%]" key={index}>
                    <div className="flex items-center justify-between menu-hover pr-[70px] py-[4px] h-[100%]">
                      <TopNavBar
                        key={index}
                        value={item?.attributes?.title}
                        path={item?.attributes?.url}
                        active={ActiveTab}
                        inActive={InactiveTab}
                      />
                    </div>
                    {item?.attributes?.child?.data?.length ? (
                      <div
                        className="invisible absolute z-50 flex  bg-white  text-gray-800 shadow-xl group-hover:visible left-0 w-[100vw] pt-[15px]"
                        key={index}
                      >
                        <div className="xl:px-20 lg:px-10 border-t-[1px] border-primary py-[50px] flex space-between w-full">
                          <div className={` w-full flex pr-5`}>
                            {item?.attributes?.child?.data?.map((ele, ind) => {
                              return setData(ele, ind);
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
          </div>

          <div>
            <CartsView />
          </div>
        </div>
      </nav>
      <nav>
        {announcementTxt && !ismobileMenuOpen && (
          <div className={`w-full bg-primary flex justify-center py-[6px]`}>
            <button
              className="font-black underline text-center text-btnTextColor underlin underline-offset-2  hover:drop-shadow-lg hover:shadow-lg hover:opacity-90"
              onClick={() => {
                navigate("/courses");
              }}
            >
              {announcementTxt}
            </button>
          </div>
        )}
      </nav>

      <Dialog
        as="div"
        className="xl:hidden flex"
        open={ismobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-[9]" />
        <Dialog.Panel className={SmDrawer}>
          <div className="flex items-center justify-between xl:mt-16 lg2:mt-16 lg:mt-16 mt-4">
            <div className="flex justify-center webkitHighlight">
              {userDetail && userToken ? (
                <OpenProfile navigate={navigate} dispatch={dispatch} />
              ) : (
                <AuthenticationRoute
                  onPressSignup={onPressSignup}
                  onPressLogin={onPressLogin}
                />
              )}
            </div>

            <Button
              className="-m-2.5 rounded-md p-2.5 text-gray-700 webkitHighlight"
              onClick={() => {
                dispatch(setIsMobileMenuOpen(false));
                setMobileMenuOpen(false);
              }}
              variant="primary"
            >
              <ClearRoundedIcon
                className="h-6 w-6 text-btnTextColor"
                aria-hidden="true"
                fontSize="large"
              />
            </Button>
          </div>
          <div className="mt-7 ">
            <ChangeLanguage
              title={language}
              titleStyle="max-w-[150px] truncate"
              handleLanguageChange={handleLanguageChange}
            />
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {headerNavLinks &&
                  [...headerNavLinks]
                    ?.sort((a, b) => a.attributes.order - b.attributes.order)
                    ?.map((item, index) => (
                      <Accordion
                        key={index}
                        value={item?.attributes?.title}
                        path={item?.attributes?.url}
                        active={MobActive}
                        inActive={MobInactive}
                        data={item?.attributes?.child?.data}
                      />
                    ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
}

const navbarView = `
w-full 
items-center 
justify-between 
xl:py-3 py-0
`;

const LargeDeviceContainer = `
flex 
w-full 
items-center 
justify-between 
xl:py-3 py-0 
gap-x-1 
`;

const LgNavStyle = `
xl:flex
flex-row
justify-between
items-center
h-[100%]
px-4 
w-full
`;

const ActiveTab = `
text-[17px] 
font-sans
font-black
underline
underline-offset-4
decoration-1
text-black 
self-center
hover:cursor-default
 `;

const InactiveTab = `
font-sans
text-[17px] 
text-black 
self-center
font-semibold`;

const AuthActive = `
lg:px-4 
px-2 
py-[8px] 
bg-primary 
rounded-full 
flex 
items-center 
justify-center`;

const AuthInactive = `
lg:px-4 
px-2 
py-[8px] 
bg-btnTextColor 
rounded-full 
flex 
items-center 
justify-center`;

const SmDrawer = `
fixed 
inset-y-0 
right-0 
z-10 
w-full 
overflow-y-auto 
bg-textColor
px-6 
py-6 
sm:max-w-sm 
sm:ring-1 sm:ring-gray-900/10`;

const MobActive = `
text-[18px] 
-mx-3 
block 
rounded-lg 
px-3 
py-2 
font-sans
text-base 
font-bold 
leading-7 
text-primary 
border-b-2 
border-b-primary
`;

const MobInactive = `
text-[18px] 
-mx-3 
font-sans
block 
rounded-lg 
px-3 
py-2 
text-base 
font-semibold 
leading-7 
text-btnTextColor
`;

const AuthText = `
text-btnTextColor 
text-[17px] 
font-bold 
font-sans
`;

const InactiveText = `
text-[#004996] 
text-[17px] 
font-bold 
font-sans
`;
