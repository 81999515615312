import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AUTHENTICATE_USER,
  GOLFCLIX_AUTHENTICATE_USER,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  REGISTER_USER,
  REGISTER_GOLFCLIX_USER,
  RESET_PASSWORD,
  CLUB_LIST,
  CLUB_LIST_UPDATE,
  MAKE_HOME_CLUB,
  USER_DETAIL,
  GOLFCLIX_UPDATE_PROFILE,
} from "./types";
import {
  // authenticateUserApi,
  // registerUserApi,
  resetPasswordApi,
} from "@services/apis";

import {
  loginUserApi,
  registerUserAPi,
  clubListApi,
  makeHomeClubApi,
  userDetailApi,
  golfClixChangePasswordApi,
  golfClixForgotPasswordApi,
  golfClixUpdateProfileApi,
} from "../../golfClixServices/auth";

// export const authenticateUser = createAsyncThunk(
//   AUTHENTICATE_USER,
//   async (data) => {
//     try {
//       const response = await authenticateUserApi(data);
//       return response;
//     } catch (err) {
//       return err;
//     }
//   }
// );

export const golfclixAuthenticateUser = createAsyncThunk(
  GOLFCLIX_AUTHENTICATE_USER,
  async (data) => {
    try {
      const response = await loginUserApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

// export const registerUser = createAsyncThunk(REGISTER_USER, async (data) => {
//   try {
//     const response = await registerUserApi(data);
//     return response;
//   } catch (err) {
//     return err;
//   }
// });

export const registerGolfclixUser = createAsyncThunk(
  REGISTER_GOLFCLIX_USER,
  async (data) => {
    try {
      const response = await registerUserAPi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getClubList = createAsyncThunk(CLUB_LIST, async (data) => {
  try {
    const response = await clubListApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const updateClubList = createAsyncThunk(
  CLUB_LIST_UPDATE,
  async (data) => {
    try {
      const response = await clubListApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const makeHomeClub = createAsyncThunk(MAKE_HOME_CLUB, async (data) => {
  try {
    const response = await makeHomeClubApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const getUserDetail = createAsyncThunk(USER_DETAIL, async (data) => {
  try {
    const response = await userDetailApi(data);
    return response;
  } catch (err) {
    return err;
  }
});

export const changePassword = createAsyncThunk(
  CHANGE_PASSWORD,
  async (data) => {
    try {
      const response = await golfClixChangePasswordApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  FORGOT_PASSWORD,
  async (data) => {
    try {
      const response = await golfClixForgotPasswordApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateProfile = createAsyncThunk(
  GOLFCLIX_UPDATE_PROFILE,
  async (data) => {
    try {
      const response = await golfClixUpdateProfileApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const resetPassword = createAsyncThunk(RESET_PASSWORD, async (data) => {
  try {
    const response = await resetPasswordApi(data);
    return response;
  } catch (err) {
    return err;
  }
});
