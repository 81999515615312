import {
  GOLFCLIX_AUTHENTICATE_USER,
  REGISTER_GOLFCLIX_USER,
  CLUB_LIST,
  MAKE_HOME_CLUB,
  USER_DETAIL,
  GOLFCLIX_CHANGE_PASSWORD,
  GOLFCLIX_FORGOT_PASSWORD,
  GOLFCLIX_UPDATE_PROFILE,
} from "@utils/config";
import { httpsAPICall } from "../apis";

export const loginUserApi = (data) => {
  const response = httpsAPICall(GOLFCLIX_AUTHENTICATE_USER, data, "post");
  return response;
};

export const registerUserAPi = (data) => {
  const response = httpsAPICall(REGISTER_GOLFCLIX_USER, data, "post");
  return response;
};

export const userDetailApi = (data) => {
  const response = httpsAPICall(`${USER_DETAIL}/${data}`, data, "get");
  return response;
};

export const clubListApi = (data) => {
  const response = httpsAPICall(
    `${CLUB_LIST}?filter=${data.filterText}&page_size=${data.pageSize}&page_no=${data.pageNo}`,
    data,
    "get"
  );
  return response;
};

export const makeHomeClubApi = (data) => {
  const response = httpsAPICall(MAKE_HOME_CLUB, data, "post");
  return response;
};

export const golfClixChangePasswordApi = (data) => {
  const response = httpsAPICall(GOLFCLIX_CHANGE_PASSWORD, data, "post");
  return response;
};
export const golfClixForgotPasswordApi = (data) => {
  const response = httpsAPICall(GOLFCLIX_FORGOT_PASSWORD, data, "post");
  return response;
};

export const golfClixUpdateProfileApi = (data) => {
  const response = httpsAPICall(
    `${GOLFCLIX_UPDATE_PROFILE}/${data.id}`,
    data,
    "put"
  );
  return response;
};
