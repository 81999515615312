export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const GOLFCLIX_AUTHENTICATE_USER = "GOLFCLIX_AUTHENTICATE_USER";
export const UPDATE_GOLFCLIX_USER = "UPDATE_GOLFCLIX_USER";
export const GOLFCLIX_UPDATE_PROFILE = "GOLFCLIX_UPDATE_PROFILE";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_GOLFCLIX_USER = "REGISTER_GOLFCLIX_USER";
export const CLUB_LIST = "CLUB_LIST";
export const CLUB_LIST_UPDATE = "CLUB_LIST_UPDATE";
export const MAKE_HOME_CLUB = "MAKE_HOME_CLUB";
export const USER_DETAIL = "USER_DETAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
